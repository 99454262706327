<template>
  <div class="container">
    <div
      class="margin"
      v-for="(item, index) in menus"
      :key="item.id"
      @click="$router.push({ name: 'sub-menu', params: { parentId: item.id, parentName: item.name } })"
    >
      <div class="row row-align-center padding shadow-lg radius15" :class="bgColor.type1">
        <div class="col-20 font-lg font-bold">{{ item.name }}</div>
        <div class="col-4"><md-icon class="icon-lg" :name="icons[index] || 'firewall'" svg></md-icon></div>
      </div>
    </div>
    <div class="margin">
      <div class="row row-align-center padding shadow-lg radius15" :class="bgColor.type2" @click="$router.push({ name: 'judge' })">
        <div class="col-16 font-lg font-bold">消防专家系统</div>
        <div class="col-4"><md-icon class="icon-xxl" name="fireFightingCar" svg></md-icon></div>
      </div>
    </div>
    <!-- <div class="center help">
      <md-button type="link" icon="info-solid" @click="$router.push({ name: 'description' })" style="font-size:18px">使用说明</md-button>
    </div> -->
  </div>
</template>

<script>
import * as menuApi from '@/api/menu'

import '@/assets/svg/house.svg'
import '@/assets/svg/product.svg'
import '@/assets/svg/device.svg'
import '@/assets/svg/fire.svg'
import '@/assets/svg/fireFightingCar.svg'
import '@/assets/svg/firewall.svg'

export default {
  data() {
    return {
      menus: [],
      icons: ['house', 'product', 'device', 'firewall', 'fire'],
      userInfo: this.$store.getters['user/info'],
      // isPro: this.$store.getters['user/isPro'],
      isPro: true,
      // isSuperPro: this.$store.getters['user/isSuperPro'],
      isSuperPro: true,
    }
  },
  computed: {
    bgColor() {
      if (this.isPro) {
        return {
          type1: 'color-bg-blue1',
          type2: 'color-bg-blue2',
        }
      }
      if (this.isSuperPro) {
        return {
          type1: 'color-bg-orange1',
          type2: 'color-bg-orange2',
        }
      }
      return {
        type1: 'color-bg-green1',
        type2: 'color-bg-green2',
      }
    },
  },
  created() {
    menuApi.list({ level: 0 }).then(res => (this.menus = res.data))
  },
}
</script>
<style lang="stylus" scoped>
.container
  height 100%
  overflow hidden
  .icon-lg
    width 70px
    height 70px
  .icon-xxl
    width 200px
    height 200px
  .help
    position fixed
    width 100%
    bottom 140px
  >>> .md-button.link
    color #2f86f6
</style>
